.navGlass {
  background: rgba(22, 22, 22, 0.87);
  box-shadow: 0 8px 32px 0 rgba(8, 8, 8, 0.37);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
}

.breadcrumb {
  position: relative;

  .line {
    width: 90%;
    height: 2px;
    border-radius: 30px;
    background-color: #ffc668;
    position: absolute;
  }

  .lin-1 {
    top: 25%;
  }
  .lin-2 {
    top: 50%;
  }
  .lin-3 {
    top: 75%;
  }
}

.opened {
  .lin-1 {
    animation: line1 0.4s forwards ease-in-out;
  }

  .lin-2 {
    animation: line2 0.4s forwards ease-in-out;
  }

  .lin-3 {
    animation: line3 0.4s forwards ease-in-out;
  }
}

@keyframes line1 {
  from {
    transform: rotate(0deg);
    top: 25%;
  }
  to {
    transform: rotate(45deg);
    top: 50%;
  }
}
@keyframes line2 {
  from {
    transform: translateX(0px);
    opacity: 1;
  }
  to {
    transform: translateX(50px);
    opacity: 0;
  }
}
@keyframes line3 {
  from {
    top: 75%;
    transform: rotate(0px);
  }
  to {
    transform: rotate(135deg);
    top: 50%;
  }
}
